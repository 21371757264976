
.breadcrumb-nav {
	padding-top: 40px;

	@include min(1280px) {
		padding-top: 60px;
	}

	&__list {
		margin: 0 0 rem(80px);
		padding: 0;
		display: inline-flex;
		flex-wrap: wrap;
		list-style-type: none;
	}

	&__item {
		&:not(:last-child)::after {
			content: "\>";
			margin: 0 .5em;
		}
	}

	&__link {
		text-decoration: underline;
		display: inline-block;

		&[aria-current] {
			pointer-events: none;
			text-decoration: none;
			font-weight: bold;
		}
	}
}
