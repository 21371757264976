/*
* BASE DEPENDENCIES
*/ /*****************************************
* Blackwell - MIXINS
*****************************************/
/*****************************************
* blackwell - FUNCTIONS
*****************************************/
/*==============================
	FONTS
==============================*/
/*==============================
	COLORS
==============================*/
/*==============================
	SIZES
==============================*/
/* COMPONENT VARIABLES */
/*==============================
	FORMS
==============================*/
/*==============================
	BUTTONS
==============================*/
/*
* LAYOUT
*/
.breadcrumb-nav {
  padding-top: 40px;
}
@media screen and (min-width: 1280px) {
  .breadcrumb-nav {
    padding-top: 60px;
  }
}
.breadcrumb-nav__list {
  margin: 0 0 5rem;
  padding: 0;
  display: inline-flex;
  flex-wrap: wrap;
  list-style-type: none;
}
.breadcrumb-nav__item:not(:last-child)::after {
  content: ">";
  margin: 0 0.5em;
}
.breadcrumb-nav__link {
  text-decoration: underline;
  display: inline-block;
}
.breadcrumb-nav__link[aria-current] {
  pointer-events: none;
  text-decoration: none;
  font-weight: bold;
}

.page-module {
  margin-bottom: 3em;
}

.page {
  padding-bottom: 100px;
}

.common-page {
  display: grid;
  grid-gap: 60px;
  gap: 60px;
}
@media screen and (min-width: 800px) {
  .common-page {
    grid-template-columns: 1fr 350px;
  }
}
@media screen and (min-width: 1280px) {
  .common-page {
    grid-gap: 190px;
    gap: 190px;
  }
}
.common-page__sidebar h2 {
  font-size: 2.25rem;
  text-align: center;
  margin: 0.4em 0 1em;
}
.common-page__sidebar .jobs__list {
  grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
  grid-gap: 32px;
  gap: 32px;
}

.subpage-languages {
  display: grid;
  grid-template-columns: initial;
  grid-gap: 20px;
  gap: 20px;
  margin-top: 0;
  position: absolute;
  right: 0;
  top: 52px;
  transform: translateY(-50%);
}
@media screen and (min-width: 1280px) {
  .subpage-languages {
    top: 72px;
  }
}

main {
  position: relative;
}

.reference-content {
  display: grid;
}
@media screen and (max-width: 600px) {
  .reference-content table tr {
    display: flex;
    flex-direction: column;
  }
  .reference-content table tr td {
    display: block;
    flex-grow: 1;
    width: 100%;
  }
}