/*
* BASE DEPENDENCIES
*/

@import "abstracts/mixins", "abstracts/functions", "abstracts/vars";

/*
* LAYOUT
*/

@import "components/breadcrumb-nav";

.page-module {
	margin-bottom: 3em;
}

.page {
	padding-bottom: 100px;
}

.common-page {
	display: grid;
	grid-gap: 60px;
	gap: 60px;

	@include min(800px) {
		grid-template-columns: 1fr 350px;
	}

	@include min(1280px) {
		grid-gap: 190px;
		gap: 190px;
	}

	&__sidebar {
		h2 {
			font-size: rem(36px);
			text-align: center;
			margin: .4em 0 1em;
		}

		.jobs__list {
			grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
			grid-gap: 32px;
			gap: 32px;
		}
	}
}

.subpage-languages {
	display: grid;
	grid-template-columns: initial;
	grid-gap: 20px;
	gap: 20px;
	margin-top: 0;
	position: absolute;
	right: 0;
	top: 52px;
	transform: translateY(-50%);

	@include min(1280px) {
		top: 72px;
	}
}

main {
	position: relative;
}

.reference-content {
	display: grid;

	table {
		@include max(600px) {
			tr {
				display: flex;
				flex-direction: column;

				td {
					display: block;
					flex-grow: 1;
					width: 100%;
				}
			}
		}
	}
}
